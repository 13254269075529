import {
  ROLE_GUEST,
  ROLE_OWNER,
  ROLE_INSPECTOR,
  ROLE_EMPLOYEE,
  PERMISSION_VIEW_COUNTERPART,
  PERMISSION_VIEW_COUNTERPART_HISTORY,
  PERMISSION_CREATE_COUNTERPART,
  PERMISSION_PRINT_COUNTERPART,
  PERMISSION_VIEW_CONTRACT,
  PERMISSION_CREATE_CONTRACT,
  PERMISSION_VIEW_FINANCIAL_MODEL,
  PERMISSION_CREATE_FINANCIAL_MODEL,
  PERMISSION_VIEW_USER,
  PERMISSION_CREATE_USER,
  PERMISSION_VIEW_OPERATION,
  PERMISSION_VIEW_OPERATION_ID,
  PERMISSION_VIEW_OPERATION_FINANCIAL_MODEL_ID,
  PERMISSION_VIEW_OPERATION_PAYSYSTEM_ID,
  PERMISSION_VIEW_OPERATION_PAYSYSTEM_NAME,
  PERMISSION_VIEW_OPERATION_TERMINAL_ID,
  PERMISSION_VIEW_OPERATION_TERMINAL_NAME,
  PERMISSION_VIEW_OPERATION_AUTH_CODE,
  PERMISSION_VIEW_OPERATION_REGISTRY_REFERENCE_NUMBER,
  PERMISSION_VIEW_OPERATION_MCC,
  PERMISSION_VIEW_OPERATION_MERCHANT_ID,
  PERMISSION_VIEW_OPERATION_INITIATOR_IP,
  PERMISSION_VIEW_OPERATION_INITIATOR_EMAIL,
  PERMISSION_VIEW_OPERATION_INITIATOR_ACCOUNT_NUMBER,
  PERMISSION_VIEW_OPERATION_ORDER_DESCRIPTION,
  PERMISSION_CREATE_OPERATION,
  PERMISSION_EXPORT_OPERATION,
  PERMISSION_VIEW_DECLINED_PAYMENT,
  PERMISSION_CREATE_DECLINED_PAYMENT,
  PERMISSION_VIEW_MONITORING,
  PERMISSION_EXPORT_MONITORING,
  PERMISSION_CREATE_REPORT,
  PERMISSION_VIEW_REPORT,
  PERMISSION_CREATE_TASK,
  PERMISSION_VIEW_TASK
} from "@/constants";
export default {
  methods: {
    roleMapper(name) {
      switch (name) {
        case ROLE_GUEST:
          return "Гість";
        case ROLE_OWNER:
          return "Власник";
        case ROLE_INSPECTOR:
          return "Перевіряючий";
        case ROLE_EMPLOYEE:
          return "Співробітник";
        default:
          return name;
      }
    },
    permissionMapper(name) {
      switch (name) {
        case PERMISSION_VIEW_COUNTERPART:
          return "Переглядати контрагента";
        case PERMISSION_VIEW_COUNTERPART_HISTORY:
          return "Переглядати історію контрагента";
        case PERMISSION_CREATE_COUNTERPART:
          return "Додавати контрагента";
        case PERMISSION_PRINT_COUNTERPART:
          return "Друк паперових форм анкет контрагента";
        case PERMISSION_VIEW_CONTRACT:
          return "Переглядати контракт";
        case PERMISSION_CREATE_CONTRACT:
          return "Додавати контракт";
        case PERMISSION_VIEW_FINANCIAL_MODEL:
          return "Переглядати профіль СПФМ";
        case PERMISSION_CREATE_FINANCIAL_MODEL:
          return "Додавати профіль СПФМ";
        case PERMISSION_VIEW_USER:
          return "Переглядати користувача";
        case PERMISSION_CREATE_USER:
          return "Додавати користувача";
        case PERMISSION_VIEW_OPERATION:
          return "Переглядати операції";
        case PERMISSION_VIEW_OPERATION_ID:
          return "Переглядати ідентифікатор операції";
        case PERMISSION_VIEW_OPERATION_FINANCIAL_MODEL_ID:
          return "Переглядати ідентифікатор профілю СПФМ операції";
        case PERMISSION_VIEW_OPERATION_PAYSYSTEM_ID:
          return "Переглядати ідентифікатор платіжної системи операції";
        case PERMISSION_VIEW_OPERATION_PAYSYSTEM_NAME:
          return "Переглядати назву платіжної системи операції";
        case PERMISSION_VIEW_OPERATION_TERMINAL_ID:
          return "Переглядати ідентифікатор терміналу операції";
        case PERMISSION_VIEW_OPERATION_TERMINAL_NAME:
          return "Переглядати назву терміналу операції";
        case PERMISSION_VIEW_OPERATION_AUTH_CODE:
          return "Переглядати авторизаційний код операції";
        case PERMISSION_VIEW_OPERATION_REGISTRY_REFERENCE_NUMBER:
          return "Переглядати реєстраційний номер операції";
        case PERMISSION_VIEW_OPERATION_MCC:
          return "Переглядати код категорії продавця операції";
        case PERMISSION_VIEW_OPERATION_MERCHANT_ID:
          return "Переглядати ідентифікатор продавця операції";
        case PERMISSION_VIEW_OPERATION_INITIATOR_IP:
          return "Переглядати ip-адресу ініціатора операції";
        case PERMISSION_VIEW_OPERATION_INITIATOR_EMAIL:
          return "Переглядати email-адресу ініціатора операції";
        case PERMISSION_VIEW_OPERATION_INITIATOR_ACCOUNT_NUMBER:
          return "Переглядати номер рахунку ініціатора операції";
        case PERMISSION_VIEW_OPERATION_ORDER_DESCRIPTION:
          return "Переглядати опис замовлення операції";
        case PERMISSION_CREATE_OPERATION:
          return "Додавати операції";
        case PERMISSION_EXPORT_OPERATION:
          return "Вивантажити операції";
        case PERMISSION_VIEW_DECLINED_PAYMENT:
          return "Переглядати заблоковані платежі";
        case PERMISSION_CREATE_DECLINED_PAYMENT:
          return "Додавати заблоковані платежі";
        case PERMISSION_VIEW_MONITORING:
          return "Переглядати поточний моніторинг";
        case PERMISSION_EXPORT_MONITORING:
          return "Вивантажити з моніторингу";
        case PERMISSION_CREATE_REPORT:
          return "Створювати нову звітність";
        case PERMISSION_VIEW_REPORT:
          return "Переглядати звітність";
        case PERMISSION_CREATE_TASK:
          return "Створювати нові завдання (Дії над контрагентами)";
        case PERMISSION_VIEW_TASK:
          return "Переглядати завдання (Дії над контрагентами)";
        default:
          return name;
      }
    }
  }
};
